<template>
  <div>
    <v-container style="padding: 0" grid-list-md  >
      <v-layout row wrap v-for="(parent, index) in parentInfo" :key="index">
        <v-flex xs12 sm4>
          <v-text-field
            :color="'blue'"
            outlined
            dense
            autocomplete="off"
            label="Parent Name"
            required
            class="pa-0"
            v-model="parent.name"

          />
        </v-flex>
        <v-flex xs12 sm3>
          <v-text-field
            outlined
            dense
            autocomplete="off"
            label="Parent Contact"
            required
            class="pa-0"
            type="number"
            v-model="parent.contact"

          />
        </v-flex>
        <v-flex xs12 sm4>
          <v-autocomplete
            outlined
            dense
            autocomplete="off"
            label="Relation"
            required
            class="pa-0"
            item
            :items="relations"
            v-model="parent.relation"

          />
          <!-- name="arae" -->
        </v-flex>
        <!-- <v-flex xs12 sm1 v-if="index !== 0"> -->
        <v-flex xs12 sm1>
          <v-btn color="error" outlined @click="removeParent(index)">
            <v-icon>close</v-icon>
        </v-btn>
        </v-flex>
        
    </v-layout>
    <p class="add-more pl-2" @click="addParents()">Add More</p>
    </v-container>
  </div>
</template>

<script>
export default{
    props:{
        parentInfo:{

        }
    },
    data:() => ({
        relations: [
        "Grandfather",
        "Grandmother",
        "Father",
        "Mother",
        "Brother",
        "Sister",
        "Uncle",
        "Aunt",
        "Other",
      ],
    }),
    mounted() {
        
    },
    methods: {
        addParents() {
            this.parentInfo.push({ name: "", contact: "", relation: "" });
        },
        removeParent(ind) {
            if(this.parentInfo.length == 1){
            if(ind == 0){
                this.parentInfo[0].name = '';
                this.parentInfo[0].contact = '';
                this.parentInfo[0].relation = '';
            }
            }else{

            this.parentInfo.splice(ind, 1);
            }
        },
    },
}
</script>
<style scoped>
.add-more {
  width: fit-content;
  color: green;
  cursor: pointer;
  text-decoration: underline;
}
</style>