<template>
    <v-container grid-list-md pa-0 relative>
        <v-layout row wrap>
            <v-flex xs12>
                <v-card>
                    <v-card-title class="title">
                        <v-icon style="margin-right: 5px; margin-top: -5px">supervised_user_circle</v-icon>
                        No Guardian Students
                    </v-card-title>

                    <v-data-table :headers="headers"
                                  :items="this.form.items.data"
                                  :loading="form.loading"
                                  footer-props.items-per-page-options="rowsPerPageItems"

                                  :options.sync="pagination"
                                  :server-items-length="form.items.meta.total">
                        <template  v-slot:item="{index, item}">
                            <tr>
                                <td>{{ ++index }}</td>
                                <td class="text-xs-left">{{ item.name }}</td>
                                <td class="text-xs-left">{{ item.grade }}<sup>"{{item.section}}"</sup></td>
                                <td class="text-xs-left">{{ item.roll }}</td>
                                <td class="text-xs-right">
                                    <v-btn @click="setStudent(item)" small icon color="warning" class="pa-0 ma-1"
                                        slot="activator">
                                        <v-icon small>{{'edit'}}</v-icon>
                                    </v-btn>
                                </td>
                            </tr>
                        </template>
                    </v-data-table>
                </v-card>
            </v-flex>
        </v-layout>

        <v-dialog v-model="updateGuardianModal" persistent max-width="850px">
            <v-card>
                <v-card-title class="primary white--text">
                    <span class="title">Update Guardian Info</span>
                </v-card-title>
                <v-card-text class="pb-1">
                    <updateguardian @cancel="updateGuardianModal=false,resetStudent()" @complete="updateGuardianModal=false,get(),resetStudent()"
                                    :student="student"></updateguardian>
                </v-card-text>
                <!--<v-card-actions>-->
                <!--<v-spacer></v-spacer>-->
                <!--<v-btn color="warning" outlined @click="form.dialog = false, form.reset()">Close</v-btn>-->
                <!--<v-btn color="success" outlined @click="save">Save</v-btn>-->
                <!--</v-card-actions>-->
            </v-card>
        </v-dialog>


        <!--Santosh Gupta-->
    </v-container>
</template>

<script>
    import {mapState} from 'vuex'
    import Form from '@/library/Form'
    import updateguardian from './../../../students/_components/Profile/pages/updateguardian'

    const NepaliDate = require('nepali-date');
    const nd = new NepaliDate();

    export default {
        components: {
            updateguardian
        },
        data: () => ({
            updateGuardianModal: false,
            form: new Form({
                name: '',
                description: '',
                company_id: '',
                parent_id: ''
            }, '/api/report/notification/no-guardian'),
            searchAH: null,
            search: null,
            isLoading: false,
            rowsPerPageItems: [5, 10, 25, 50, 75],
            pagination: {
                rowsPerPage: 25,
            },
            student: {
                id: '',
                selectedGuardian: {
                    id: '',
                    relation: '',
                    family: {
                        grand_father: '',
                        father: '',
                        mother: ''
                    }
                }
            },
            entries: [],
            accountHeads: [],
            headers: [
                {text: '#', align: 'left', sortable: false, value: 'id', width: '20px'},
                {text: 'Name', value: 'name', align: "left", width: '40%', sortable: false},
                {text: 'Grade', value: 'grade', align: "left", width: '20%', sortable: false},
                {text: 'Roll', value: 'roll', align: "left", width: '10%', sortable: false},
                {text: 'Action', value: 'action', align: "right", width: '10%', sortable: false},
            ]
        }),

        computed: {
            ...mapState(['batch']),
            items() {
                return this.entries.map(entry => {
                    return {Name: entry.name, id: entry.id};
                })
            }
        },

        watch: {
            'pagination': function () {
                this.get();
            },
            'batch': function (value) {
                this.get();
            },
        },
        methods: {
            queryString() {
                let json = this.pagination;
                return '?' +
                    Object.keys(json).map(function (key) {
                        if (![null, undefined].includes(json[key]))
                            return encodeURIComponent(key) + '=' + encodeURIComponent(json[key]);
                    }).join('&') + '&search=' + this.searchAH;
            },

            get(params) {
                let query = [null, undefined].includes(params) ? this.queryString() : params;
                this.form.get(null, query).then(({data}) => {
                    this.pagination.totalItems = data.meta.total
                })
            },
            setStudent(item) {
                this.updateGuardianModal = true;
                this.student.id = item.id;
            },
            resetStudent() {
                this.student = {
                    id: '',
                    selectedGuardian: {
                        id: '',
                        relation: '',
                        family: {
                            grand_father: '',
                            father: '',
                            mother: ''
                        }
                    }
                }
            }
        },
        mounted() {

        }
    }
</script>
<style lang="scss">
    .icon {
        font-size: 15px;
        color: black;
    }


</style>

